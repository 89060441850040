export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logoImg = require("../assets/QuilaLogo.png");
export const sideImage = require("../assets/sideImage.png");
export const googleLogoImg = require("../assets/googleLogo.svg");
export const backIcon = require("../assets/backIcon.svg");
export const dropDown = require("../assets/Vector.png");
export const backgroundWomenImage=require("../assets/backgroundWomenImage.png")
export const backBtnImg=require("../assets/backBtnImg.png")
export const userImg=require("../assets/userCircle.png")
export const signupImg=require("../assets/signUpImg.png")
export const backBtn=require("../assets/backIcon.png")
export const notFoundImg=require("../assets/notFoundImg.png")