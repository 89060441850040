import React from "react";

// Customizable Area Start
import { Box, Typography, Button, Divider, Grid } from '@material-ui/core';
import styled from '@material-ui/styles/styled';
import { rightBgImg, backIcon, blueSparkImg} from "./assets"

import TermsConditionsController, {
  Props,
} from "./TermsConditionsController";
// Customizable Area End

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
      <Grid container>
        {/* Content Side */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ContentContainer>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Box display="flex" alignItems="center" onClick={() => (window.location.href = "/EmailAccountRegistration")}>
                <img src={backIcon} alt="Back" style={{ marginRight: '8px' }} /> {/* Replace with actual path */}
                <Typography variant="body1" style={{color:"#757676"}}>Back</Typography>
              </Box>
              <img src={blueSparkImg} alt="Icon" style={{ width: '24px', height: '24px' }} /> {/* Replace with actual path */}
            </Box>
            <TermsTitle>Terms of Service</TermsTitle>
            <Typography variant="subtitle2" gutterBottom style={{color:"#6E6E6E"}}>
              Last updated on 5/12/2024
            </Typography>
            <Divider />
            <Box mt={2}>
              <Typography variant="body2" paragraph style={{color:"#6E6E6E"}}>
                Last Revised: December 18, 2023
                Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors
                and may be used for informational purposes only. Because the Terms and Conditions
                contain legal obligations, please read them carefully.
              </Typography>
              <Typography variant="body2" paragraph style={{color:"#6E6E6E"}}>
                1. YOUR AGREEMENT By using this site, you agree to be bound by, and to comply with,
                these Terms and Conditions. If you do not agree to these Terms and Conditions, please
                do not use this site.
              </Typography>
              <Typography variant="body2" paragraph style={{color:"#6E6E6E"}}>
                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or
                otherwise alter these Terms and Conditions at any time. Unless otherwise indicated,
                amendments will become effective immediately.
              </Typography>
            </Box>
            <ActionsContainer>
              <StyledButton variant="contained" color="primary">
                Agree
              </StyledButton>
              <StyledButton variant="outlined">
                Cancel
              </StyledButton>
            </ActionsContainer>
          </ContentContainer>
        </Grid>

        {/* Image Side */}
        <Grid item xs={false} sm={false} md={6} lg={6} xl={6}>
           <Box>
              <StyledImage
                src={rightBgImg}
                alt="login image"
              />
          </Box>
        </Grid>
      </Grid>
    </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContentContainer = styled(Box)({
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const ActionsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  marginTop: '40px',
});

const StyledButton = styled(Button)({
  width: '100%',
  maxWidth: '330px',
  textTransform: 'capitalize',
  fontSize: '16px',
  borderRadius: '8px',
});

const TermsTitle = styled(Typography)({
  color: '#6551EA',
  marginTop:"30px",
  fontWeight: 'bold',
  fontSize: '24px',
  marginBottom: '6px',
});

const StyledImage = styled("img")({
  width: "50%",
  right: 0,
  height: "100vh",
  position:"fixed",
  objectFit: "cover",
  opacity: "1",
  display: "flex",
  "@media (max-width: 599px)": {
    display: "none"
  }
})
// Customizable Area End
