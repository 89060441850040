Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.loginText = "Log in";
exports.laberOR = "OR";
exports.btnTxtSocialLogin = "Sign up with Google";
exports.descriptionText = "By clicking below you agree to";
exports.descriptionTextTwo = "Quila Terms and Conditions";
exports.emailRegx = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
exports.pCharacters ="Must be at least ";
exports.pCharactersTwo = "8 characters";
exports.date = "Date";
exports.tenNumber = "Ten";
exports.monthsText ="Month";
exports.janText = "jan";
exports.yrsText = "years";
exports.yrsTextTwo = "2024";
exports.dobText = "Date of Birth*";
exports.signUpApiEndpoint = "account_block/accounts";
exports.passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
exports.fnameReg = /[^A-Za-z]/g;
exports.passOneReg = /\d/;
exports.passSecondReg = /^\S+$/;
exports.passMsg = "Must have 8 characters , including at least one lowercase letter, one uppercase letter,one digit and one special character";
exports.letsgettoknow="Let's get to know you";
exports.createyouraccount="Create your account"
exports.nameText="Name*";
exports.emailText="Email*";
exports.passText="Password*";
exports.firstNameErrorText="Please enter valid first name";
exports.lastNameErrorText="Please enter valid last name";
exports.passErrorText="Your password mustbe at least 8 characters";
exports.emailErrorText="Invalid email address. Please try again";
exports.dobErrorText="You are not eligible to create an account";
exports.skipAccountText="Skip Account Registration";
exports.back="Back";
exports.guestMode="Guest Mode";
exports.guestModeDes="Some features are restricted in Guest Mode. For the full experience,Please Sign Up.";
exports.continueBooking="Continue to Booking Page";
exports.pleaseTryAgain="Please Try Again";
exports.notFoundDesc="It seems like we've taken a wrong turn. The page you're looking for isn't here. But don't worry,We're here to help you find your way back to beatuy bliaa!";
exports.pageNotFoundText="Oops! Page Not Found";
exports.forBeautyEnthusiasts="For Beauty Enthusiasts";
exports.forBeautyProfessionals="For Beauty Professionals";
// Customizable Area End
