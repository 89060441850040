import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  TextField,
  styled,
  Select,
  MenuItem,
  FormControl,
  Button
} from "@material-ui/core";
import {
  googleLogoImg,
  signupImg,
  backBtn,
  backgroundWomenImage,
  backBtnImg,
  userImg,
  notFoundImg
} from "./assets"
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
export const configJSON = require("./config");
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDate = () => {
    const customIcon = <ExpandMoreIcon style={{ position: "absolute", top: "50%", right: "12px", transform: "translateY(-50%)" }}
    />;
    return (
      <Box className="input_field_box" gridGap={10}>
        <FormControl className="date_select_field">
          <Select
            data-test-id="dobTestID"
            error={this.state.dobMsg}
            style={this.state.dobMsg ? webStyle.dateBox : {}}
            defaultValue={Date}
            className="date_select_field"
            variant="outlined"
            displayEmpty
            labelId="Date"
            inputProps={{ "aria-label": "Without label" }}
            value={this.state.dobPlaceholder}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleDateChange({ target: { value: parseInt(event.target.value as string, 10) } })}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "20px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => customIcon}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.dobPlaceholder}>{this.state.dobPlaceholder}</MenuItem>
            {this.state.dayValue.map((item: number) => (
              <MenuItem key={item} value={item} style={webStyle.dateStyle}
                disabled={this.state.selectYear === new Date().getFullYear() && this.state.selectMonth - 1 === new Date().getMonth() && item > new Date().getDate() - 1}
              >
                {item}
              </MenuItem>
            ))}

          </Select>
        </FormControl>
        <FormControl className="date_select_field">
          <Select
            data-test-id="dobMonthTestID"
            error={this.state.monthMsg}
            style={this.state.monthMsg ? webStyle.dateBox : {}}
            className="date_select_field"
            variant="outlined"
            displayEmpty
            labelId="Date"
            inputProps={{ "aria-label": "Without label" }}
            placeholder="date"
            value={this.state.monthPlaceholder}
            onChange={this.handleMonthChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "10px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => customIcon}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.monthPlaceholder}>{this.state.monthPlaceholder}</MenuItem>
            {this.state.monthValue.map((item: { id: number; monthTitle: string }) => (

              <MenuItem key={item.id} value={item.id} style={webStyle.dateStyle}
                disabled={this.state.selectYear === new Date().getFullYear() && item.id > new Date().getMonth() + 1}
              >
                {item.monthTitle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <>
        <Box style={{position:"absolute",zIndex:99}}>
        {this.state.yearMsg&& (
          <Box sx={{...webStyle.customTooltip,width:"70%",marginTop:"-33px",marginLeft:"180px"}}
          >
            <Typography style={webStyle.validationText}>{configJSON.dobErrorText}</Typography>
            <Box sx={{...webStyle.positionContainer,left:"40%"}}
            />
          </Box>
        )}
      </Box>
        <FormControl className="date_select_field" style={{position:"relative"}}>
          <Select
            data-test-id="dobYearTestID"
            error={this.state.yearMsg}
            className="date_select_field"
            style={this.state.yearMsg ? webStyle.dateBox : {}}
            variant="outlined"
            displayEmpty
            labelId="MonthDate"
            inputProps={{ "aria-label": "Without label" }}
            onChange={this.handleYearChange}
            placeholder="Month"
            value={this.state.yearPlaceholder}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "10px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => customIcon}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.yearPlaceholder}>{this.state.yearPlaceholder}</MenuItem>
            {this.state.yearValue.map((item: number) => (
              <MenuItem key={item} value={item} style={webStyle.dateStyle}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </>
      </Box>
    )
  }

  renderSignupBox = () => {
    const getBorderBoxStyle = () => {
      return this.state.isLogin ? "1px solid #6551ea" : "1px solid #757676";
    };

    const getBoxTextColor = () => {
      return this.state.isLogin ? "#6551ea" : "#757676";
    };
    return (
      <LoginSignupBox>
        <LoginText
         data-test-id="toggleID"
          style={{
            borderBottom: getBorderBoxStyle(),
            color: getBoxTextColor(),
          }}
          onClick={() => this.toggleForm(false)}>
          {configJSON.loginText}
        </LoginText>
        <LoginText style={{ borderBottom: this.state.isLogin ? "1px solid #757676" : "1px solid #6551ea", color: this.state.isLogin ? "#757676" : "#6551ea" }}
         >{configJSON.btnTextSignUp}</LoginText>
      </LoginSignupBox>
    )
  };

  renderFirstNameText =()=>{
    return(
      <>
      <Box style={{position:"absolute",zIndex:99}}>
        {this.state.fnameError && (
          <Box sx={{...webStyle.customTooltip,width:"90%"}}
          >
            <Typography style={webStyle.validationText}>{configJSON.firstNameErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </Box>
      <>
      <TextField
      data-test-id="fisrtnameID"
      error={this.state.fnameError}
      label={this.state.fname ? "" : "First Name"}
      variant="outlined"
      value={this.state.fname}
      style={webStyle.textInput}
      onChange={(e) => this.setFname(e.target.value)}
      className="input_field_config"
      InputLabelProps={{
        disableAnimation: true,
        shrink: false,
        style: {
          color: this.state.fnameError ? "#E53881" : "#757676",
          opacity: 1,
        }
      }}
      InputProps={{
        style: {
          color: this.state.fnameError ? "#E53881" : "#757676",
          border: this.state.fnameError ? "0.5px solid #E53881" : "",
        }
      }}
    />
     </>
    </>
    )
  }

  renderEmailText = () => {
    return (
      <>
      <Box >
        {this.state.emailError&& (
          <Box sx={{...webStyle.customTooltip,width:"60%"}}
          >
            <Typography style={webStyle.validationText}>{configJSON.emailErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </Box>
      <Box style={{position:'relative'}}>
      <TextField
        data-test-id="emailTestID"
        error={this.state.emailError}
        label={this.state.emailID ? "" : "Email address"}
        variant="outlined"
        style={webStyle.inputEmailStyle}
        className="input_field_config"
        value={this.state.emailID}
        onChange={(e) => this.setEmail(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.fnameError ? "#E53881" : "#757676",
            opacity: 1,
          }
        }}
        InputProps={{
          style: {
            color: this.state.fnameError ? "#E53881" : "#757676",
            border: this.state.fnameError ? "0.5px solid #E53881" : "",
          }
        }}
        />
        </Box>
      </>
    )
  }

  renderPasswordText = () => {
    return (
      <>
      <Box >
        {this.state.createPassError && (
          <Box sx={{...webStyle.customTooltip,width:"70%"}}
          >
            <Typography style={webStyle.validationText}>{configJSON.passErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </Box>
      <>
      <TextField
        data-test-id="paswordTestID"
        error={this.state.createPassError}
        label={this.state.createPassword ? " " : "Create a password (8-16 characters)"}
        variant="outlined"
        style={webStyle.inputEmailStyle}
        className="input_field_config"
        value={this.state.createPassword}
        onChange={(e) => this.setPassword(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.fnameError ? "#E53881" : "#757676",
            opacity: 1,
          }
        }}
        InputProps={{
          style: {
            color: this.state.fnameError ? "#E53881" : "#757676",
            border: this.state.fnameError ? "0.5px solid #E53881" : "",
          }
        }}
      />
      </>
      </>
    )
  }

  renderLastNameText = () => {
    return (
      <>
      <Box style={{position:"absolute",zIndex:99}}>
        {this.state.lnameError && (
          <Box sx={{...webStyle.customTooltip,marginLeft:"220px",width:"40%"}}
          >
            <Typography style={webStyle.validationText}>{configJSON.lastNameErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </Box>
      <>
      <TextField
        data-test-id="lasttnameID"
        error={this.state.lnameError}
        label={this.state.lname ? "" : "Last Name"}
        variant="outlined"
        style={webStyle.textInput}
        className="input_field_config"
        value={this.state.lname}
        onChange={(e) => this.setLname(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.lnameError ? "#E53881" : "#757676",
            opacity: 1,
          }
        }}
        InputProps={{
          style: {
            color: this.state.lnameError ? "#E53881" : "#757676",
            border: this.state.lnameError ? "0.5px solid #E53881" : "",
          }
        }}
      />
      </>
      </>
    )
  }

  renderSignUpBeforePage=()=>{
    return(

      <Box style={signUpBeforeStyles.backgroundImageContainer}>
      <img
        src={backgroundWomenImage}
        alt="backgroundImage"
        style={signUpBeforeStyles.backgroundImageStyle} 
      />
      <Box
        style={signUpBeforeStyles.topContainer}
      >
        <Box style={signUpBeforeStyles.topSubContainer}>
        <Box style={signUpBeforeStyles.backBtnContainer}>
          <BackBtnImage src={backBtnImg} alt="backBtnImg" style={signUpBeforeStyles.backImgStyle}/>
        <Box>
        <Typography style={signUpBeforeStyles.backBtnText}>{configJSON.back}</Typography>
    </Box>
    </Box>
    <Box style={signUpBeforeStyles.backBtnContainer}>
        <Box>
        <Typography style={signUpBeforeStyles.backBtnText}>{configJSON.loginText}</Typography>
    </Box>
          <img src={userImg} alt="backBtnImg" style={signUpBeforeStyles.backImgStyle}/>
    </Box>
        </Box>
      </Box>
  <Box
    style={signUpBeforeStyles.btnContainer}
  >
    <Button  style={signUpBeforeStyles.btnStyle} data-test-id="beautyTestId1" onClick={this.handleButtonClick}>
      {configJSON.forBeautyProfessionals}
    </Button>
    <Button style={{...signUpBeforeStyles.btnStyle,padding:"5px 88px"}} data-test-id="beautyTestId2" onClick={this.handleButtonClick}>
      {configJSON.forBeautyEnthusiasts}
    </Button>
  </Box>
</Box>
    )
  }

  renderPageNotFound=()=>{
    return(
       <Box style={pageNotFoundStyles.mainContainer}>
        <Box style={pageNotFoundStyles.subContainer}>
          <img src={notFoundImg} alt="notFound" style={pageNotFoundStyles.imgStyle}/>
          <Box>
          <Typography style={pageNotFoundStyles.notFoundText} data-test-id="pageNotFound">{configJSON.pageNotFoundText}</Typography>
          <Typography style={pageNotFoundStyles.notFoundDesc}>{configJSON.notFoundDesc}</Typography>
          </Box>
          
          <Button style={pageNotFoundStyles.tryAgainBtn} onClick={this.handleTryAgain}>{configJSON.pleaseTryAgain}</Button>
          </Box>
       </Box>
    )
  }

  renderSkipAccountRegistration=()=>{
    return(
   <Box style={pageNotFoundStyles.mainContainer}>
        <Box style={pageNotFoundStyles.subContainer}>
          <Box>
          <Typography style={pageNotFoundStyles.notFoundText} data-test-id="guestMode">{configJSON.guestMode}</Typography>
          <Typography style={pageNotFoundStyles.notFoundDesc}>{configJSON.guestModeDes}</Typography>
          </Box>
          
          <Button style={{...pageNotFoundStyles.tryAgainBtn,width:"60%"}} onClick={this.handleContinueBoooking}>{configJSON.continueBooking}</Button>
          </Box>
       </Box>
    )
    
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
      <style>
      {`
        .css-view-1dbjc4n {
          overflow-x: hidden; 
        }
      `}
    </style>
      <Box className="css-view-1dbjc4n">
        {(() => {
          if (this.state.isRedirectingToPageNotFound) {
            return this.renderPageNotFound()
          } else if (this.state.skipRegistration) {
            return this.renderSkipAccountRegistration()
          } else {
            return <Box >
              {this.state.showSignUpForm ? <Grid container >
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                  <Box >
                    <Box style={webStyle.topContainer}>
                      <Box style={webStyle.backBtnContainer}>
                        <img src={backBtn} alt="backBtn" onClick={this.handleBackButtonClick} />
                        <BackText>{configJSON.back}</BackText>
                      </Box>
                      <Box>
                        <Typography style={{...webStyle.loginTextt,fontFamily:"Degular Display"}}>{configJSON.loginText}</Typography>
                      </Box>
                    </Box>
                    <MainTextinputContainer>
                      <form onSubmit={this.handleCheck} data-test-id="signInBtn">
                        <Box className="login_box">
                          <Box className="tab_box">
                            <Typography className="get_to_know_text" data-test-id="lestgettoknow">{configJSON.letsgettoknow}</Typography>
                            <Typography className="create_your_account_text">{configJSON.createyouraccount}</Typography>
                          </Box>
                          <Typography style={webStyle.lableText}>{configJSON.nameText}</Typography>
                          <Box className="input_field_box">
                            {this.renderFirstNameText()}
                            {this.renderLastNameText()}
                          </Box>
                          <Typography style={webStyle.lableText}>{configJSON.emailText}</Typography>
                          {this.renderEmailText()}
                          <Typography style={webStyle.lableText}>{configJSON.passText}</Typography>
                          {this.renderPasswordText()}
                          <Typography style={webStyle.lableText}>{configJSON.dobText}</Typography>
                          {this.renderDate()}
                          <Box style={webStyle.singupBtnContainer}>
                            <Typography className="login_desc_txt">
                              {configJSON.descriptionText}{" "}
                              <span className="login_desc_span_txt">
                                {configJSON.descriptionTextTwo}
                              </span>
                            </Typography>
                            <Button className="singup_btn"
                              type="submit"
                            >{configJSON.btnTextSignUp}</Button>
                            <Box className="social_login_box">
                              <img
                                className="social_login_img"
                                src={googleLogoImg}
                              />
                              <Typography className="social_login_txt">
                                {configJSON.btnTxtSocialLogin}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography style={webStyle.skipRegistration} data-test-id="skipRegistration" onClick={this.handleSkipRegistration}>{configJSON.skipAccountText}</Typography>
                        </Box>
                      </form>
                    </MainTextinputContainer>
                  </Box>
                </Grid>
                <Grid item xs={false} sm={6} md={6} lg={6} xl={6}>
                  <Box>
                    <SideImaViewContainer
                      src={signupImg}
                      alt="login image"
                    />
                  </Box>
                </Grid>
              </Grid> : this.renderSignUpBeforePage()}
            </Box>
          }
        })()}
      </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const EmailAccount_Main_Container = styled(Box)({
  height: "100%"
});

const StyledBox = styled(Box)({
  width: '98vw',
});

const MainTextinputContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5%",
  margin:"32px",
  "& .login_box": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media (max-width:598px)": {
      width: "300px"
    }
  },

  "& .tab_box": {
    marginBottom: "37px"
  },

  "& .tab_btn_txt": {
    textAlign: "center",
    borderBottom: "1px solid #757676",
    padding: "10px 0",
    cursor: "pointer",
    width: "48%",
    fontFamily: "normal",
    fontSize: "18px",
    fontWeight: 700,
    color: "#757676"
  },

  "& .input_field_box": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom:"20px"
  },

  "& .input_field_config": {
    color: "red",
    "& .MuiOutlinedInput-root": {
      height: "55px",
    },

    "& .MuiOutlinedInput-input": {
      padding: "11px 14px 10px",
      color:"#344054"
    }
  },

  "& .date_select_field": {
    width: "100%",
    height: "44px",
    color: "#757676",
    borderRadius: "7px",
    "@media (max-width:598px)": {
      width: "90px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px"
    }
  },

  "& .date_select_fieldError": {
    width: "100px",
    height: "44px",
    color: "red",
    background: "rgba(229, 57, 53, 0.15)",
    borderRadius: "7px",
    "@media (max-width:598px)": {
      width: "90px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
      background: "rgba(229, 57, 53, 0.15)"
    }
  },

  "& .year_select_field": {
    width: "123px",
    height: "44px",
    color: "#757676",
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px"
    }
  },

  "& .divider_box": {
    display: "flex",
    justifyContent: "space-between",
    width: "inherit",
    alignItems: "center",
    marginBottom: "26px",
    marginTop:"14px"
  },

  "& .divider_txt": {
    color: "#757676",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: "-0.12px"
  },

  "& .divider_line": {
    height: "1.1px",
    width: "45%",
    backgroundColor: "#D9D9D9"
  },

  "& .social_login_box": {
    border: "1px solid #D9D9D9",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "44px",
    borderRadius: "6px",
    marginBottom: "56px",
    cursor: "pointer",
    marginTop:"10px"
  },

  "& .social_login_img": {
    position: "absolute",
    left: "12px",
    top: "12px"
  },

  "& .social_login_txt": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "140%",
    fontStyle: "normal",
    fontFamily:"Degular"
  },

  "& .login_desc_txt": {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: " -0.1px",
    textAlign: "center",
    marginBottom: "20px",
    fontFamily:"Inter"
  },

  "& .login_desc_span_txt": {
    color: "#6551EA"
  },

  "& .singup_btn": {
    height: "44px",
    borderRadius: "8px",
    backgroundColor: "#6551EA",
    color: "#FFF",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    width:"100%",
    textTransform:"capitalize",
    fontFamily:"Degular",
  },
  "& .get_to_know_text":{
    color: "#6551EA",
    fontSize:"28px",
    fontWeight:"700",
    lineHeight: "140%",
    fontFamily:"Degular Display !important"
  },
  "& .create_your_account_text":{
    color: "#757676",
    fontSize:"14px",
    lineHeight: "140%",
    fontFamily:"Degular Display",
    fontWeight:500
  }

});

const LoginSignupBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "inherit",
  marginBottom: "32px"

})

const LoginText = styled(Typography)({
  textAlign: "center",
  padding: "10px 0",
  cursor: "pointer",
  width: "48%",
  fontSize: "17px",
  fontWeight: "bold",
  fontFamily:"Degular Display !important"
})


const BackBtnImage = styled("img")({
  width: "100%",
  cursor:"pointer",
  objectFit: "cover",
  "@media (max-width:598px)": {
    flexDirection: "column",
    width: "100%"
  }
})

const SideImaViewContainer = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  opacity: "1",
  display: "flex",
  "@media (max-width: 599px)": {
    display: "none"
  }
})
const BackText = styled(Typography)({
  color: "#757676",
  fontSize: "16px",
  display: "block",
  fontWeight:500,
  fontFamily:"Degular Display !important",
  "@media (max-width: 598px)": {
   display:"none"
  }
})
  

const webStyle = {
  textInput: {
    width: "48%",
  },

  inputEmailStyle: {
    width: "100%",
    marginBottom: "20px"
  },

  passwordText: {
    color: "#757676",
    fontSize: "12px",
    marginBottom: "15px",
    marginLeft: "10px"
  },

  passwordTextError: {
    color: "red",
    fontSize: "12px",
    marginBottom: "15px",
    marginLeft: "10px"
  },

  dobStyleError: {
    color: "red",
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "5px",
    fontWeight: 500
  },

  dobStyle: {
    color: "#757676",
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "5px",
    fontWeight: 500
  },

  dateStyle: {
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "140%",
    fontSize: "14px"
  },

  dateBox: {
    color: "#E53881",
    border:"0.5px solid #E53881"
  },
  lableText:{
    color:"#344054",
    fontSize:"14px",
    lineHeight: "140%",
    marginBottom:"5px",
    fontWeight:870,
    fontFamily:"Degular Display",
  },
  singupBtnContainer:{
    marginTop:"80px"
  },
  topContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    padding:"20px 40px",
  },
  loginTextt:{
    color:"#757676",
    fontSize:"16px",
    fontFamily:"Degular Display !important",
    fontWeight:500
  },
  
  backBtnContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:10
  },
  customTooltip: {
    marginTop: '-34px',
    boxShadow: '0px 4.412px 8.824px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#E53881',
    color: 'white',
    padding: '10px 15px',
    position: 'relative',
    width:"100%",
    borderRadius:"6px",
  },
  positionContainer: {
     position: 'absolute',
     top: '100%',
     left: '20%',
     transform: 'translateX(-50%)',
     borderTop: '10px solid #E53881',
     borderLeft: '10px solid transparent',
     borderRight: '10px solid transparent',          
  },
  validationText:{
    fontSize:"12px",
    color:"#fff",
    fontWeight:600
  },
  skipRegistration:{
    color:"#6551EA",
    fontSize:"12px",
    textAlign:"center" as "center",
    cursor:"pointer",
    fontFamily:"Inter",
    fontWeight:500
  }
}
const signUpBeforeStyles={
  backgroundImageContainer:{ width: '100%', height: '100vh', position: 'relative' as "relative" },
  backgroundImageStyle:{ width: '100%', height: '100%',  },
  topContainer:{
    position: 'absolute' as "absolute",
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1,
  },
  topSubContainer:{padding:"25px",display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',},
  btnContainer:{
    position: 'absolute' as "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column' as "column",
    alignItems: 'center',
    zIndex: 1, 

    width:"100%",
    '@media (max-width: 598px)': {
      width: '100%',
    },
  },
  btnStyle:{
    backgroundColor:"#6320F2",
    color:"#fff",
    padding:"5px 80px",
    marginBottom:"15px",
    textTransform: "capitalize" as "capitalize",
    borderRadius:"6px",
    fontSize:"18px",
    fontWeight:700,
    fontFamily:"Degular",
  },
  backBtnContainer:{
    display:'flex',
    alignItems:'center',
    justifyContetn:"center",
    gap:10
  },
  backImgStyle:{
    cursor:'pointer'
  },
  backBtnText:{
    color:'#fff',
    fontSize:"16px",
    cursor:"pointer",
    fontFamily:"Degular",
    fontWeight:500
  }
}

const pageNotFoundStyles={
  mainContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    height:"100vh",
  },
  subContainer:{
    textAlign:"center" as "center",
    padding:"20px"
  },
  imgStyle:{
    marginBottom:30
  },
  notFoundText:{
    marginBottom:10,
    color:"#000",
    fontSize:"48px",
    fontWeight:'bold' as "bold"
  },
  notFoundDesc:{
    marginBottom:80,
    color:"#757676",
  },
  tryAgainBtn:{
  backgroundColor:"#6551EA",
  width:"50%",
  color:"#fff",
  borderRadius:"6px",
  textTransform:"capitalize" as "capitalize"
  }
}
// Customizable Area End